<script>
// Extensions
import View from "@/views/public/View";
import { orderTypeStoreActions } from "@/store/modules/orderType/constNames";
import { orderTypeName } from "@/store/modules/orderType/constNames";

export default {
  name: "Menu",

  metaInfo: { title: "Menu" },

  extends: View,

  mounted() {
    const dateTime = moment();
    localStorage.setItem("time", dateTime);
    localStorage.setItem("tableId", this.$route.params.tableId);
    this.$store.dispatch(orderTypeStoreActions.SAVE_ORDER_TYPE, {
      name: orderTypeName.LOCAL,
      preselected: true,
    });
    this.$store.dispatch(orderTypeStoreActions.UPDATE_IS_CONFIGURED, true);
    this.$router.replace(`/menu`);
  },
};
</script>
